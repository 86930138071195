import React from 'react';
import Header from './Header';
import Footer from './Footer';
import image from '../assets/undercons.png';
import { Helmet } from 'react-helmet';


const Home = () => {

    return (
    <div className='overflow-hidden'>
        <Helmet>
            <title>Blog | Surfheim VPN</title>
            <meta name="description" content="Welcome to Surfheim VPN. Experience fast, secure, and reliable VPN services." />
            <meta name="keywords" content="VPN, secure browsing, online privacy" />
            <meta property="og:title" content="Home | Surfheim VPN" />
            <meta property="og:description" content="Welcome to Surfheim VPN. Experience fast, secure, and reliable VPN services." />
            <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
            <meta property="og:url" content="https://www.surfheim.com" />
        </Helmet>

        <Header />
        <div className='mx-auto flex justify-center items-center sm:py-12 sm:px-36 bg-gradient-to-br from-active/15 via-accent to-active'>
            <div className='flex flex-col lg:gap-x-[30px] gap-y-6 lg:gap-y-0 lg:flex-row md:flex-row items-center justify-center text-center lg:text-left'>
                <div className='flex-1'>
                    <h1 className='title mb-2 lg:mb-5 mt-4 md:mt-0'> Website is Under Construction</h1>
                    <p className='lead mb-5 lg:mb-10 px-2'>Stay tuned by following us on social media</p>
                    <div className='flex items-center max-w-sm lg:max-w-full mx-auto lg:mx-0 gap-x-2 lg:gap-x-6'>
                        
                        <a href='https://surfheim.com'>
                            <button
                            className='btn btn-md lg:btn-lg btn-accent flex justify-center items-center lg:gap-x-4'>
                            Learn more
                            </button>
                        </a>
                    </div>
                </div>
                <div className='flex-1'>
                    <img src={image}  alt='underConstruction'/>
                </div>
            </div>

      </div>
          
          <Footer />
    </div>
    )
  };
  
  export default Home;