import React, { useState } from 'react';
import logo from '../assets/logo.png';
import { FaChevronDown  } from "react-icons/fa";


const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className='py-2 w-full bg-white'>
            <div className='flex flex-row items-center justify-center md:justify-between'>
                <div className='flex flex-row items-center'>
                    <a href='https://surfheim.com/' >
                        <img className='w-48 sm:w-64 ml-2 sm:ml-10' src={logo} alt='surfheim'/>
                    </a>
                </div>

                <div className='hidden md:flex flex-row gap-x-4 mt-1 ml-12 font-secondary pr-8'>
                    <a href='https://surfheim.com/' className='text-accentHover font-semibold'>Home</a>
                    <a href='https://surfheim.com/Pricing/' className='text-accentHover font-semibold'>Pricing</a>
                    <a href='https://surfheim.com/Downloads/' className='text-accentHover font-semibold'>Download</a>
                    <a href='https://surfheim.com/About-us/' className='text-accentHover font-semibold'>About us</a>
                </div>
                
            </div>
            
            
            <div className='flex flex-col items-center justify-center'>
            
                <button className='md:hidden text-accentHover' onClick={toggleMenu}>
                    <FaChevronDown size={20} />
                </button>
                {isMenuOpen && (
                <div className='md:hidden flex flex-col gap-y-2 font-secondary items-center'>
                    <a href='https://surfheim.com/' className='text-accentHover font-semibold'>Home</a>
                    <a href='https://surfheim.com/Pricing/' className='text-accentHover font-semibold'>Pricing</a>
                    <a href='https://surfheim.com/Downloads/' className='text-accentHover font-semibold'>Order</a>
                    <a href='https://surfheim.com/About-us/' className='text-accentHover font-semibold'>About us</a>
                </div>
            )}
            </div>

            
        </div>
    );
};


export default Header;